import axios from 'axios'
import humps from 'humps'
import qs from 'qs'

const { REACT_APP_PAYSG_URL: PAYSG_URL } = process.env

// Config to snake_case outgoing post body and query strings, and camelCase incoming
// responses
const baseConfiguration = {
  // Configure base url
  baseURL: `${PAYSG_URL}`,
  // Inject device headers into all outgoing requests.
  // headers: someHeaders,
  // Add our own transformation after the default setting transform
  transformResponse: [...axios.defaults.transformResponse, (data) => humps.camelizeKeys(data)],
  // Add our transformation before passing to default setting to transform
  transformRequest: [(data) => humps.decamelizeKeys(data), ...axios.defaults.transformRequest],
  paramsSerializer: (params) => {
    return qs.stringify(humps.decamelizeKeys(params))
  },
}

// Create the api service which operational needs calls
const ApiService = axios.create(baseConfiguration)

export default ApiService
