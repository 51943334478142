import React from 'react'
import { Box, Divider, Flex, Heading, HStack, Link, Spinner, Text, VStack } from '@chakra-ui/react'

// Utils
import { formatTime, formatInvoiceNo } from 'services/utils'

const Receipt = ({ payment, service }) => {
  return (
    <>
      <Box w="100%" h="100%" bg="white" shadow="md" borderRadius="lg" p="3rem" mt="2rem">
        <VStack>
          {payment && service ? (
            <>
              <Heading mb={2} size="lg">
                Tax Invoice and Receipt
              </Heading>
              <VStack mb={6} w="100%" fontSize="sm" fontWeight="bold">
                <Text w="100%" color="gray.600" align="center">
                  {service.agencyName}
                </Text>
                <Text w="100%" color="gray.500" align="center">
                  {service.agencyAddress}
                </Text>
              </VStack>
              <VStack mb={8} w="100%" fontSize="sm" fontWeight="bold">
                <HStack w="100%">
                  <Text w="30%" color="gray.500">
                    GST Reg No:
                  </Text>
                  <Text w="70%" color="gray.600">
                    {service.agencyGstRegNum}
                  </Text>
                </HStack>
                <HStack w="100%">
                  <Text w="30%" color="gray.500">
                    Reference ID:
                  </Text>
                  <Text w="70%" color="gray.600">
                    {payment.referenceId}
                  </Text>
                </HStack>
                <HStack w="100%">
                  <Text w="30%" color="gray.500">
                    Invoice No:
                  </Text>
                  <Text w="70%" color="gray.600">
                    {formatInvoiceNo(payment.id)}
                  </Text>
                </HStack>
                <HStack w="100%">
                  <Text w="30%" color="gray.500">
                    Invoice Date:
                  </Text>
                  <Text w="70%" color="gray.600">
                    {formatTime(payment.paymentSucceededTimestamp)}
                  </Text>
                </HStack>
              </VStack>
              <HStack w="100%" color="gray.500" fontSize="xs" fontWeight="semibold">
                <Text w="50%">Description</Text>
                <Text w="20%" textAlign="center">
                  Quantity
                </Text>
                <Text w="30%" textAlign="right">
                  Amount (SGD)
                </Text>
              </HStack>
              <Divider mb={2} />
              <HStack w="100%" align="top" mb={2}>
                <Text w="50%">{payment.description}</Text>
                <Text w="20%" textAlign="center">
                  1
                </Text>
                <Text w="30%" textAlign="right">{`$${(payment.amountSubtotal / 100).toFixed(
                  2
                )}`}</Text>
              </HStack>
              {payment.taxAmount > 0 && (
                <>
                  <Divider mb={2} />
                  <HStack w="100%" justify="flex-end" color="gray.600" mb={2} fontSize="xs">
                    <Text mr={4}>Sub-Total</Text>
                    <Text w="20%" textAlign="right">{`$${(payment.amountSubtotal / 100).toFixed(
                      2
                    )}`}</Text>
                  </HStack>
                  <Divider mb={2} />
                  <HStack w="100%" justify="flex-end" color="gray.600" mb={2} fontSize="xs">
                    <Text mr={4}>GST (7%)</Text>
                    <Text w="20%" textAlign="right">{`$${(payment.taxAmount / 100).toFixed(
                      2
                    )}`}</Text>
                  </HStack>
                </>
              )}
              <Divider mb={2} />
              <HStack w="100%" justify="flex-end" color="gray.600" fontWeight="bold" mb={10}>
                <Text mr={4}>Total</Text>
                <Text w="20%" textAlign="right">{`$${(payment.amount / 100).toFixed(2)}`}</Text>
              </HStack>
              <Text mb={3}>
                Thank you for making payment for {service.title}. Your payment has been received.
                For any disputes or queries, please email {`${service.agencyName}`} at{' '}
                <Link href={`mailto:${service.agencyEmail}`} color="blue">
                  {`${service.agencyEmail}`}
                </Link>
                .
              </Text>
            </>
          ) : (
            <Box width="100%" height="100%" minHeight="300px">
              <Flex direction="columns" justify="center" align="center" mt="16">
                <Spinner />
              </Flex>
            </Box>
          )}
        </VStack>
      </Box>
    </>
  )
}

export default Receipt
