import React from 'react'
import fallbackAgencyLogo from 'img/fallbackLogo.png'

import { Box, Image, Heading, Flex, Spinner, Text, VStack } from '@chakra-ui/react'

const Main = ({ payment, service }) => {
  return (
    <>
      <Flex justify="center" align="center" w="100%" position="relative">
        <Box
          borderRadius="50%"
          overflow="hidden"
          shadow="md"
          w="100px"
          h="100px"
          position="absolute"
        >
          <Flex bg="white" justify="center" align="center">
            <Image src={fallbackAgencyLogo} />
          </Flex>
        </Box>
      </Flex>

      <Box w="100%" h="100%" bg="white" shadow="md" borderRadius="lg">
        {' '}
        <Box w="100%" bg="primary.700" borderTopRadius="lg" px="2rem" pt="5rem" pb="3rem">
          {service ? (
            <Flex direction="column" justify="center" align="center">
              <Heading
                as="h1"
                fontSize="4xl"
                color="white"
                mb="1rem"
                textAlign="center"
                lineHeight="1.1"
              >
                {service.title || 'Payment for Government Services'}
              </Heading>
              <Heading as="h2" fontSize="xl" color="white" textAlign="center">
                {service.agency_name || 'Government of Singapore'}
              </Heading>
            </Flex>
          ) : (
            <Box width="100%">
              <Flex direction="columns" justify="center" align="center" mt="16">
                <Spinner color="white" />
              </Flex>
            </Box>
          )}
        </Box>
        <Box p="3rem">
          <Heading size="lg" mb={6}>
            INFORMATION
          </Heading>
          {payment && service ? (
            <VStack spacing="2rem" width="100%" justify="flex-start" align="flex-start">
              <Text>{service.information}</Text>
              <Box>
                <Heading size="xs" mb="2" color="gray.500" textTransform="uppercase">
                  Reference ID
                </Heading>
                <Text>{payment.referenceId}</Text>
              </Box>
              <Box>
                <Heading size="xs" mb="2" color="gray.500" textTransform="uppercase">
                  Amount
                </Heading>
                <Text>${(payment.amount / 100).toFixed(2)}</Text>
              </Box>
              <Box>
                <Heading size="xs" mb="2" color="gray.500" textTransform="uppercase">
                  Description
                </Heading>
                <Text>{payment.description}</Text>
              </Box>
            </VStack>
          ) : (
            <Box width="100%" height="100%" minHeight="300px">
              <Flex direction="columns" justify="center" align="center" mt="16">
                <Spinner />
              </Flex>
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

export default Main
