import React from 'react'
import ReactDOM from 'react-dom'
import { ColorModeScript } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import App from './App'

if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_PUBLIC_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

ReactDOM.render(
  <React.StrictMode>
    <ColorModeScript />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
