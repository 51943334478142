import React from 'react'

import Lottie from 'lottie-react'
import checkAnimation from 'img/check-animation.json'

import { Box, Button, Heading, Container, Flex, Spinner, Text, VStack } from '@chakra-ui/react'

// Components
import PoweredBy from 'commons/PoweredBy'

const PaymentLinkSuccess = ({ payment, goToPaymentLinkPage }) => {
  // Display spinner if link is not loaded yet
  if (!payment) {
    return (
      <Box width="100%" height="100%" minHeight="300px">
        <Flex direction="columns" justify="center" align="center" mt="16">
          <Spinner />
        </Flex>
      </Box>
    )
  }

  return (
    <>
      <Box w="100%" h="100%" bg="gray.100">
        <Container maxW="container.sm" py="5rem">
          <Box w="100%" h="100%" bg="white" shadow="md" borderRadius="lg" padding="3rem">
            <Flex width="100%" direction="column" justify="center" align="center" mb="3rem">
              <Box w="150px" h="150px" mb="1rem">
                <Lottie animationData={checkAnimation} loop="false" />
              </Box>
              <Heading fontSize="3xl" mb=".5rem" color="gray.600">
                Payment Success
              </Heading>
              <Heading fontSize="5xl">${(payment.amount / 100).toFixed(2)}</Heading>
            </Flex>
            <VStack spacing="2rem" width="100%" justify="flex-start" align="flex-start">
              <Box>
                <Heading size="xs" mb="2">
                  Reference ID
                </Heading>
                <Text color="gray.800">{payment.referenceId}</Text>
              </Box>

              <Box>
                <Heading size="xs" mb="2">
                  Description
                </Heading>
                <Text color="gray.800">{payment.description}</Text>
              </Box>
              <Button
                onClick={goToPaymentLinkPage}
                height="3rem"
                width="100%"
                colorScheme="primary"
              >
                Return to main page
              </Button>
            </VStack>
          </Box>
          <PoweredBy />
        </Container>
      </Box>
    </>
  )
}

export default PaymentLinkSuccess
