import React, { useEffect, useState } from 'react'
import { Box, Container, Text } from '@chakra-ui/react'
import { useStripe } from '@stripe/react-stripe-js'

import PoweredBy from 'commons/PoweredBy'
import { useParams } from 'react-router-dom'
import PayApi from 'services/PayApi'
import AmountSummary from './components/AmountSummary'
import Checkout from './components/Checkout'
import Main from './components/Main'
import ProceedButton from './components/ProceedButton'
import Receipt from './components/Receipt'

const STATUS = {
  NOT_LOADED: 0,
  NOT_PAID: 1,
  PAID: 2,
  REFUNDED: 3,
}

const PaymentLinkMainContainer = ({
  payment,
  service,
  goToPaymentLinkSuccessPage,
  goToPaymentLinkErrorPage,
}) => {
  const stripe = useStripe()
  const [showCheckoutSection, setShowCheckoutSection] = useState(false)
  const [isProceedButtonLoading, setIsProceedButtonLoading] = useState(true)
  const [linkStatus, setLinkStatus] = useState(STATUS.NOT_LOADED)
  const [clientSecret, setClientSecret] = useState('')
  const paymentId = useParams()
  const handlePayClick = () => {
    setShowCheckoutSection(true)
  }

  useEffect(() => {
    setIsProceedButtonLoading(true)
    if (payment.chargeRefundedTimestamp) {
      setLinkStatus(STATUS.REFUNDED)
      setIsProceedButtonLoading(false)
      return
    }
    PayApi.getClientSecret({ paymentId: paymentId.paymentId })
      .then(
        async (fetchedClientSecret) => {
          setClientSecret(fetchedClientSecret)
          const { paymentIntent } = await stripe.retrievePaymentIntent(fetchedClientSecret)
          setLinkStatus(() => {
            if (['succeeded', 'processing'].includes(paymentIntent.status)) {
              return STATUS.PAID
            }
            return STATUS.NOT_PAID
          })
        },
        (error) => {
          console.log(error)
          goToPaymentLinkErrorPage()
        }
      )
      .finally(() => {
        setIsProceedButtonLoading(false)
      })
  }, [
    payment,
    paymentId,
    setClientSecret,
    setLinkStatus,
    setIsProceedButtonLoading,
    goToPaymentLinkErrorPage,
    stripe,
  ])

  return (
    <>
      <Box bg="gray.100" h="100%">
        {window.location.search === '?formsg=true' && (
          <Box bg="green.300" px={4} py={2}>
            <Text>Your form response has been submitted.</Text>
          </Box>
        )}
        <Container maxW="container.sm" py="5rem">
          <Main
            payment={payment}
            service={service}
            handlePayClick={handlePayClick}
            shouldShowPayButton={!showCheckoutSection}
            isProceedButtonLoading={isProceedButtonLoading}
          />
          {linkStatus === STATUS.PAID && <Receipt payment={payment} service={service} />}
          {linkStatus === STATUS.NOT_PAID && <AmountSummary payment={payment} />}
          {showCheckoutSection && (
            <Checkout
              payment={payment}
              clientSecret={clientSecret}
              goToPaymentLinkSuccessPage={goToPaymentLinkSuccessPage}
              goToPaymentLinkErrorPage={goToPaymentLinkErrorPage}
            />
          )}
          <ProceedButton
            payment={payment}
            handlePayClick={handlePayClick}
            disableProceedButton={linkStatus !== STATUS.NOT_PAID}
            shouldShowProceedButton={!showCheckoutSection}
            isProceedButtonLoading={isProceedButtonLoading}
            isRefund={linkStatus === STATUS.REFUNDED}
          />
          <PoweredBy />
        </Container>
      </Box>
    </>
  )
}

export default PaymentLinkMainContainer
