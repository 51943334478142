import baseConfig from './baseConfig'

// Stripe =========================================
// For making payment with Stripe Elements

function getClientSecret({ paymentId }) {
  console.log('[INFO] getClientSecret')
  return baseConfig
    .get(`/payment-link/${paymentId}/get-client-secret`)
    .then((res) => res.data.clientSecret)
}

// Payments =================================================
// For management of payment links

function getPayment({ paymentId }) {
  console.log('[INFO] getPayment')
  return baseConfig.get(`/payments/${paymentId}`).then((res) => res.data)
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getClientSecret,
  getPayment,
}
