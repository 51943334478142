/* eslint-disable no-bitwise */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash'
import moment from 'moment'
import { parse, transforms } from 'json2csv'

// This file is for small named pure functions that could be used in multiple places

export function isClientError(error) {
  const errorCode = _.get(error, 'response.data.error.code')
  return errorCode && errorCode >= 400 && errorCode < 500
}

export function getErrorMessage(error) {
  return _.get(error, 'response.data.error.message')
}

// Returns a formatted timestamp from a timestamp string or moment obj if the input is valid.
// Else, returns '-'
export function formatTime(timestamp) {
  if (!timestamp) {
    return '-'
  }
  const momentified = moment(timestamp)
  return momentified.isValid() ? momentified.format('D MMM YYYY h:mm A') : '-'
}

// TODO: Might not want to put a very specific search filter here although it is a pure function
function escapeRegExp(str) {
  // eslint-disable-next-line
  return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
}
export function searchFilter(data, filterKey, filterValue) {
  // filter = 'CHANGI BEACH CARPARK'
  // Regex will look like this: /(?=.*CHANGI)(?=.*BEACH)(?=.*CARPARK)/
  // Only returns true when all 3 words are in string
  const myFilter = new RegExp(
    `(?=.*${escapeRegExp(_.toUpper(filterValue))})`.replace(/\s+/g, ')(?=.*')
  )
  return data.filter((each) => {
    const property = each[filterKey]
    return myFilter.test(_.toUpper(property))
  })
}

export function jsonToCsvUri(jsonData) {
  // TODO: The csv parser is now the synchronous one which can be a performance issue when
  // the data grows
  // The reason to use this now is for easy dynamic metadata field discovery where each object could
  // have differing keys in their metadata object. With this, the whole data is loaded into memory,
  // allowing the csv headers to be populated with the fields.
  // The async api on the other hand will base all csv headers from the first object
  const csvData = parse(jsonData, {
    transforms: [transforms.flatten({ separator: '_' })],
  })
  return encodeURIComponent(csvData)
}

export function triggerCsvDownloadWithUriAndFilename(csvUri, filename) {
  const element = document.createElement('a')
  element.setAttribute('href', `data:text/plain;charset=utf-8,${csvUri}`)
  element.setAttribute('download', filename)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}

export function formatInvoiceNo(paymentId) {
  return paymentId.replace('payment_', '')
}
