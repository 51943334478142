import React from 'react'
import { ChakraProvider, Box } from '@chakra-ui/react'

import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom'

// customising chakra ui theme with pay colors and font
import Theme from 'Theme'
import Fonts from 'Fonts'

// import "styles/styles.scss";
import Masthead from 'commons/Masthead'
import OgpFooter from 'commons/OgpFooter'
import Landing from 'pages/Landing'
import PaymentLink from 'pages/PaymentLink/PaymentLinkController'
import PaymentLinkNotFound from 'pages/PaymentLinkNotFound'

import Routes from 'constants/routes'

function App() {
  return (
    <ChakraProvider theme={Theme}>
      <Fonts />
      <Box bg="white">
        <Masthead />
        <Router>
          <Switch>
            <Route exact path={Routes.ROOT_ROUTE} component={Landing} />
            <Route path={Routes.PAYMENT_LINK_ROUTE} component={PaymentLink} />
            <Route
              exact
              path={Routes.PAYMENT_LINK_NOT_FOUND_ROUTE}
              component={PaymentLinkNotFound}
            />
            <Redirect from="*" to={Routes.ROOT_ROUTE} />
          </Switch>
        </Router>
      </Box>

      <OgpFooter />
    </ChakraProvider>
  )
}

export default App
