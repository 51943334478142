import React from 'react'

import { Box, Button } from '@chakra-ui/react'
import { ArrowForwardIcon, CheckIcon } from '@chakra-ui/icons'

const ProceedButton = ({
  payment,
  handlePayClick,
  disableProceedButton,
  shouldShowProceedButton,
  isProceedButtonLoading,
  isRefund,
}) => {
  function getButtonContent() {
    if (isRefund) {
      return <>REFUNDED</>
    }
    if (disableProceedButton) {
      return (
        <>
          PAID
          <CheckIcon w={4} h={4} ml={2} />
        </>
      )
    }
    return (
      <>
        PROCEED
        <ArrowForwardIcon w={5} h={5} ml={1} />
      </>
    )
  }
  return (
    <>
      {shouldShowProceedButton && (
        <Box mt="2rem" w="100%">
          <Button
            colorScheme="primary"
            size="lg"
            height="4rem"
            w="100%"
            onClick={handlePayClick}
            shadow="md"
            disabled={!payment || disableProceedButton}
            isLoading={!payment || isProceedButtonLoading}
          >
            {getButtonContent()}
          </Button>
        </Box>
      )}
    </>
  )
}

export default ProceedButton
