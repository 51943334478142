import { useCallback, useEffect, useState } from 'react'

// Services
import PayApi from 'services/PayApi'

export default function usePaymentAndServiceData(paymentId) {
  const [error, setError] = useState()
  const [payment, setPayment] = useState()
  const [service, setService] = useState()
  const [shouldRefetch, setShouldRefetch] = useState(false)

  useEffect(() => {
    async function fetchData() {
      setError('')
      try {
        const { payment: fetchedPayment, service: fetchedService } = await PayApi.getPayment({
          paymentId,
        })

        setPayment(fetchedPayment)
        setService(fetchedService)
      } catch (fetchedError) {
        console.error(fetchedError?.response?.data?.error?.message)
        setError(fetchedError?.response?.data?.error?.message || 'An error occurred')
      }
    }

    fetchData()
  }, [paymentId, shouldRefetch])

  const refetch = useCallback(() => {
    setShouldRefetch((state) => !state)
  }, [])

  return {
    error,
    payment,
    service,
    refetch,
  }
}
