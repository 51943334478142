import React from 'react'
import { Box, Container, Flex, Image, Link } from '@chakra-ui/react'

import productLogo from 'img/paysgLogo.svg'

const ProductHeader = () => {
  return (
    <Box w="100%">
      <Container maxW="96em">
        <Flex justify="space-between" py="5">
          <Flex justify="center" align="center">
            <Link href="/">
              <Image w="180px" src={productLogo} />
            </Link>
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}

export default ProductHeader
