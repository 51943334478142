import { Alert, AlertIcon, Flex } from '@chakra-ui/react'

const TestModeHeader = () => {
  return (
    <Flex>
      <Alert status="warning" justifyContent="center">
        <AlertIcon />
        Test Mode: No actual payments will be collected.
      </Alert>
    </Flex>
  )
}

export default TestModeHeader
