import React from 'react'
import { Box, Heading, Container, Flex, Text } from '@chakra-ui/react'

// Components
import PoweredBy from 'commons/PoweredBy'

const PaymentLinkError = () => (
  <>
    <Box w="100%" h="100%" bg="gray.100">
      <Container maxW="container.sm" py="5rem">
        <Box w="100%" h="100%" bg="white" shadow="md" borderRadius="lg" padding="3rem">
          <Flex width="100%" direction="column" justify="center" textAlign="center" mb={5}>
            <Heading color="red.600" fontSize="4xl" mb={5}>
              Payment link not found
            </Heading>
          </Flex>
          <Text fontSize="0.9em">
            There are no payment links with this ID. Please check the that URL you tried to access
            is the same as the one sent to you in the email.
          </Text>
          <br />
          <Text fontSize="0.9em">
            If the problem still persists, please contact us at{' '}
            <u>
              <a href="mailto:feedback@pay.gov.sg" rel="noreferrer" target="_blank">
                feedback@pay.gov.sg
              </a>
            </u>
            .
          </Text>
        </Box>
        <PoweredBy />
      </Container>
    </Box>
  </>
)

export default PaymentLinkError
