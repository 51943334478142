import React from 'react'
import ProductHeader from 'commons/ProductHeader'
import { Container, Flex, Heading, Image, Text, SimpleGrid } from '@chakra-ui/react'
import paySgGirl from 'img/paySgGirl.svg'

const Landing = () => {
  return (
    <>
      <ProductHeader />
      <Container maxW="96em">
        <SimpleGrid columns={2} py={20} spacing={10} height="100%">
          <Flex direction="column">
            <Text fontSize="2xl" color="tertiary.500" mb={4} mt={20} lineHeight={1.4}>
              Fuss-free government payments
            </Text>
            <Heading as="h1" size="2xl" color="tertiary.500" lineHeight={1.2}>
              PaySG makes it easy for citizens to pay Government.
            </Heading>
          </Flex>
          <Flex>
            <Image src={paySgGirl} />
          </Flex>
        </SimpleGrid>
      </Container>
    </>
  )
}

export default Landing
