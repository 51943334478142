import React from 'react'
import { Flex, Spinner } from '@chakra-ui/react'

const Loading = () => {
  return (
    <Flex w="100%" h="calc(100vh - 30px - 252px)" justifyContent="center" alignItems="center">
      <Spinner size="xl" />
    </Flex>
  )
}

export default Loading
