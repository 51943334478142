import React from 'react'
import productLogo from 'img/paysgLogo.svg'

import { Box, Image, Flex, Text, Link, HStack } from '@chakra-ui/react'

const PoweredBy = () => {
  return (
    <Box mt="5rem">
      <Flex direction="column" align="center" justify="center">
        <Text mb=".5em" color="gray.600" fontSize="md">
          {' '}
          Powered by{' '}
        </Text>
        <Link to="/">
          <Image width="180px" src={productLogo} alt="Pay SG Logo" />
        </Link>

        <HStack spacing="2em" mt="2em">
          <Link to="/" color="gray.600">
            <Text fontSize="sm">Privacy Policy</Text>
          </Link>
          <Link to="/" color="gray.600">
            <Text fontSize="sm">Terms of Use</Text>
          </Link>
          <Link to="/" color="gray.600">
            <Text fontSize="sm">Contact Us</Text>
          </Link>
        </HStack>
      </Flex>
    </Box>
  )
}

export default PoweredBy
