import React, { useEffect, useState } from 'react'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'

import { Box, Button, Flex, Heading, Input, Text, useToast } from '@chakra-ui/react'

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
  showIcon: true,
}

const Checkout = ({
  payment,
  clientSecret,
  goToPaymentLinkSuccessPage,
  goToPaymentLinkErrorPage,
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const [isLoading, setIsLoading] = useState(false)
  const [cardElementsState, setCardElementsState] = useState({
    cardNumber: false,
    cardCvc: false,
    cardExpiry: false,
  })
  const [isCardReady, setCardReady] = useState(false)
  const [cardName, setCardName] = useState('')
  const toast = useToast()

  // add listeners to detect when credit card fields are completed
  useEffect(() => {
    const cardNumber = elements.getElement(CardNumberElement)
    const cardCvc = elements.getElement(CardCvcElement)
    const cardExpiry = elements.getElement(CardExpiryElement)
    // Card element is ready when complete and not erroneous
    cardNumber.on('change', (event) => {
      if (event.complete || event.error) {
        setCardElementsState((prevState) => {
          return {
            cardNumber: event.complete && !event.error,
            cardCvc: prevState.cardCvc,
            cardExpiry: prevState.cardExpiry,
          }
        })
      }
    })
    cardCvc.on('change', (event) => {
      if (event.complete || event.error) {
        setCardElementsState((prevState) => {
          return {
            cardCvc: event.complete && !event.error,
            cardNumber: prevState.cardNumber,
            cardExpiry: prevState.cardExpiry,
          }
        })
      }
    })
    cardExpiry.on('change', (event) => {
      if (event.complete || event.error) {
        setCardElementsState((prevState) => {
          return {
            cardExpiry: event.complete && !event.error,
            cardNumber: prevState.cardNumber,
            cardCvc: prevState.cardCvc,
          }
        })
      }
    })
  }, [elements])

  // set card ready when all fields are "complete"
  useEffect(() => {
    setCardReady(
      cardElementsState.cardCvc && cardElementsState.cardExpiry && cardElementsState.cardNumber
    )
  }, [cardElementsState])

  const handleSubmit = async (event) => {
    // Prevent default form submission from happening
    event.preventDefault()
    setIsLoading(true)

    if (!stripe || !elements || !isCardReady || !cardName) {
      // Handle stripe or elements has not loaded yet
      setIsLoading(false)
      return
    }

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: cardName,
        },
      },
    })
    setIsLoading(false)

    if (result.error) {
      // @todo prevent duplicate toasts
      toast({
        title: 'Payment Error',
        description: result.error.message,
        duration: null,
        isClosable: true,
        position: 'top',
        status: 'error',
      })
    } else if (result.paymentIntent.status === 'succeeded') {
      goToPaymentLinkSuccessPage()
    } else {
      goToPaymentLinkErrorPage()
    }
  }

  return (
    <>
      <Box w="100%" h="100%" bg="white" shadow="md" borderRadius="lg" p="3rem" mt="2rem">
        <Heading size="lg">CARD PAYMENT</Heading>
        <form id="stripe-checkout" onSubmit={handleSubmit}>
          <Box mt={6}>
            <Text fontSize="sm" fontWeight="semibold" color="gray.500" mb={1}>
              Card information
            </Text>
            <Box shadow="sm">
              <Box
                p={3}
                border="1px"
                borderBottom="0px"
                borderColor="gray.300"
                borderTopRadius="base"
              >
                <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
              </Box>
              <Flex width="100%">
                <Box
                  flexGrow="1"
                  p={3}
                  border="1px"
                  borderRight="0px"
                  borderColor="gray.300"
                  borderBottomLeftRadius="base"
                >
                  <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
                </Box>
                <Box
                  flexGrow="1"
                  p={3}
                  border="1px"
                  borderColor="gray.300"
                  borderBottomRightRadius="base"
                >
                  <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
                </Box>
              </Flex>
            </Box>
          </Box>
          <Box mt={6}>
            <Text fontSize="sm" fontWeight="semibold" color="gray.500" mb={1}>
              Name on card
            </Text>
            <Input
              type="text"
              value={cardName}
              borderColor="gray.300"
              shadow="sm"
              onChange={(event) => setCardName(event.target.value)}
            />
          </Box>
        </form>
      </Box>
      <Box mt="2rem" w="100%">
        <Button
          colorScheme="primary"
          size="lg"
          height="4rem"
          w="100%"
          shadow="md"
          form="stripe-checkout"
          type="submit"
          disabled={!stripe || isLoading || !isCardReady || !cardName}
          isLoading={isLoading}
        >
          {`Pay SGD ${(payment.amount / 100).toFixed(2)}`}
        </Button>
      </Box>
    </>
  )
}

export default Checkout
