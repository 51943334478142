import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import TestModeBanner from 'commons/TestModeBanner'
import Loading from 'commons/Loading'
import Routes from 'constants/routes'
import usePaymentAndServiceData from 'hooks/usePaymentAndServiceData'
import PaymentLinkMain from './components/PaymentLinkMain'
import PaymentLinkSuccess from './components/PaymentLinkSuccess'
import PaymentLinkError from './components/PaymentLinkError'

const PAYMENT_LINK_PAGE = 'payment_link'
const PAYMENT_LINK_SUCCESS_PAGE = 'payment_link_success'
const PAYMENT_LINK_ERROR_PAGE = 'payment_link_error'

const PaymentLinkController = () => {
  const { paymentId } = useParams()
  const history = useHistory()
  const { error, payment, refetch, service } = usePaymentAndServiceData(paymentId)
  const [page, setPage] = useState(PAYMENT_LINK_PAGE)
  const [stripePromise, setStripePromise] = useState()

  // function to initialise stripe
  const initialiseStripe = async () => {
    if (service) {
      const options = {}
      if (service.stripeAccountId) {
        options.stripeAccount = service.stripeAccountId
      }
      setStripePromise(await loadStripe(process.env.REACT_APP_STRIPE_PK, options))
    }
  }

  // scroll to top on page change
  // refetch payment data on page change
  useEffect(() => {
    window.scrollTo(0, 0)
    refetch()
  }, [page])

  // redirect to 404 page if error
  useEffect(() => {
    if (error) {
      history.push(Routes.PAYMENT_LINK_NOT_FOUND_ROUTE)
    }
  }, [error])

  // initialise stripe when service is loaded
  useEffect(() => {
    initialiseStripe()
  }, [service])

  /**
   * Page Navigations
   */
  const goToPaymentLinkPage = () => {
    setPage(PAYMENT_LINK_PAGE)
  }
  const goToPaymentLinkSuccessPage = () => {
    setPage(PAYMENT_LINK_SUCCESS_PAGE)
  }
  const goToPaymentLinkErrorPage = () => {
    setPage(PAYMENT_LINK_ERROR_PAGE)
  }
  return (
    <>
      {process.env.REACT_APP_ENV !== 'production' && <TestModeBanner />}
      {service && stripePromise ? (
        <Elements stripe={stripePromise}>
          {page === PAYMENT_LINK_PAGE && (
            <PaymentLinkMain
              payment={payment}
              service={service}
              goToPaymentLinkSuccessPage={goToPaymentLinkSuccessPage}
              goToPaymentLinkErrorPage={goToPaymentLinkErrorPage}
            />
          )}
          {page === PAYMENT_LINK_SUCCESS_PAGE && (
            <PaymentLinkSuccess payment={payment} goToPaymentLinkPage={goToPaymentLinkPage} />
          )}
          {page === PAYMENT_LINK_ERROR_PAGE && (
            <PaymentLinkError
              payment={payment}
              goToPaymentLinkPage={goToPaymentLinkPage}
              error={error}
            />
          )}
        </Elements>
      ) : (
        <Loading />
      )}
    </>
  )
}

export default PaymentLinkController
