import React from 'react'

import { Box, Divider, Heading, HStack, Flex, Spinner, Text, VStack } from '@chakra-ui/react'

const AmountSummary = ({ payment }) => {
  return (
    <>
      <Box w="100%" h="100%" bg="white" shadow="md" borderRadius="lg" p="3rem" mt="2rem">
        <Heading size="lg">AMOUNT SUMMARY</Heading>
        <VStack mt={6}>
          {payment ? (
            <>
              <HStack
                w="100%"
                justify="space-between"
                color="gray.500"
                fontWeight="semibold"
                mb={2}
              >
                <Text w="20%">QTY</Text>
                <Text w="50%">ITEM</Text>
                <Text w="30%" textAlign="right">
                  AMOUNT
                </Text>
              </HStack>
              <HStack w="100%" justify="space-between" align="top" mb={2}>
                <Text w="20%">1</Text>
                <Text w="50%">{payment.description}</Text>
                <Text w="30%" textAlign="right">{`$${(payment.amount / 100).toFixed(2)}`}</Text>
              </HStack>
              <Divider mb={2} />
              <HStack w="100%" justify="space-between" color="gray.600" fontWeight="bold">
                <Text>Total</Text>
                <Text textAlign="right" fontSize="3xl">{`$${(payment.amount / 100).toFixed(
                  2
                )}`}</Text>
              </HStack>
            </>
          ) : (
            <Box width="100%" height="100%" minHeight="300px">
              <Flex direction="columns" justify="center" align="center" mt="16">
                <Spinner />
              </Flex>
            </Box>
          )}
        </VStack>
      </Box>
    </>
  )
}

export default AmountSummary
