import React from 'react'
import { Box, Button, Heading, Container, Flex, Text } from '@chakra-ui/react'

// Components
import PoweredBy from 'commons/PoweredBy'

const PaymentLinkError = ({ error, goToPaymentLinkPage }) => {
  return (
    <>
      <Box w="100%" h="100%" bg="gray.100">
        <Container maxW="container.sm" py="5rem">
          <Box w="100%" h="100%" bg="white" shadow="md" borderRadius="lg" padding="3rem">
            <Flex width="100%" direction="column" justify="center" textAlign="center" mb={5}>
              <Heading color="red.600" fontSize="4xl" mb={5}>
                An error occurred
              </Heading>
              <Text mb={5}>{error}</Text>
            </Flex>
            <Text fontSize="0.9em">
              There was an error in processing the payment. Please click the button below to try
              again.
            </Text>
            <br />
            <Text fontSize="0.9em">
              If the problem still persists, please contact us at{' '}
              <u>
                <a href="mailto:feedback@pay.gov.sg" rel="noreferrer" target="_blank">
                  feedback@pay.gov.sg
                </a>
              </u>
              .
            </Text>
            <br />
            <br />
            <Button
              onClick={goToPaymentLinkPage}
              height="3rem"
              width="100%"
              colorScheme="primary"
              mb={3}
            >
              Try again
            </Button>
          </Box>
          <PoweredBy />
        </Container>
      </Box>
    </>
  )
}

export default PaymentLinkError
